import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './App.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const skillLevels = {
  Base: [
    { name: 'Запрос', value: 1.7 },
    { name: 'Отчет', value: 0.7 },
    { name: 'Лимит', value: 1.0 },
    { name: 'Платеж', value: 0.9 },
  ],
  PRO: [
    { name: 'Запрос', value: 1.8 },
    { name: 'Отчет', value: 0.9 },
    { name: 'Лимит', value: 1.0 },
    { name: 'Платеж', value: 1.0 },
  ],
  VIP: [
    { name: 'Запрос', value: 2.0 },
    { name: 'Отчет', value: 0.9 },
    { name: 'Лимит', value: 1.0 },
    { name: 'Платеж', value: 1.0 },
  ]
};

const App = () => {
  const [entries, setEntries] = useState([{ clientId: '', category: 'Запрос' }]);
  const [selectedSkill, setSelectedSkill] = useState('Base'); // Состояние для уровня скилла

  const categories = skillLevels[selectedSkill]; // Получаем категории для текущего уровня

  const handleInputChange = (index, field, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][field] = value;
    setEntries(updatedEntries);
  };

  // Обновленная функция для добавления нового поля с содержимым из буфера обмена
  const addEntry = async () => {
    try {
      // Считываем содержимое буфера обмена
      const clipboardText = await navigator.clipboard.readText();
      setEntries([...entries, { clientId: clipboardText, category: 'Запрос' }]);
    } catch (error) {
      console.error("Ошибка при получении данных из буфера обмена:", error);
      setEntries([...entries, { clientId: '', category: 'Запрос' }]);
    }
  };

  const calculateTotals = () => {
    const totals = {
      totalPoints: 0,
      queryPoints: 0,
      reportPoints: 0,
      limitPoints: 0,
      paymentPoints: 0,
      categoryPoints: {
        'Запрос': 0,
        'Отчет': 0,
        'Лимит': 0,
        'Платеж': 0,
      }
    };

    entries.forEach(entry => {
      const category = categories.find(cat => cat.name === entry.category);
      if (category) {
        totals.totalPoints += category.value;
        totals.categoryPoints[entry.category] += category.value;

        if (entry.category === 'Запрос') totals.queryPoints += category.value;
        if (entry.category === 'Отчет') totals.reportPoints += category.value;
        if (entry.category === 'Лимит') totals.limitPoints += category.value;
        if (entry.category === 'Платеж') totals.paymentPoints += category.value;
      }
    });

    totals.totalPoints = parseFloat(totals.totalPoints.toFixed(1));
    totals.queryPoints = parseFloat(totals.queryPoints.toFixed(1));
    totals.reportPoints = parseFloat(totals.reportPoints.toFixed(1));
    totals.limitPoints = parseFloat(totals.limitPoints.toFixed(1));
    totals.paymentPoints = parseFloat(totals.paymentPoints.toFixed(1));

    return totals;
  };

  const totals = calculateTotals();

  const chartData = {
    labels: ['Запрос', 'Отчет', 'Лимит', 'Платеж'],
    datasets: [
      {
        label: 'Сумма баллов',
        data: [
          totals.categoryPoints['Запрос'],
          totals.categoryPoints['Отчет'],
          totals.categoryPoints['Лимит'],
          totals.categoryPoints['Платеж']
        ],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(54, 162, 235, 0.6)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 206, 86, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const exportToExcel = () => {
    const worksheetData = [
      ['ID Клиента', 'Категория', 'Баллы']
    ];

    entries.forEach(entry => {
      const category = categories.find(cat => cat.name === entry.category);
      worksheetData.push([entry.clientId, entry.category, category.value]);
    });

    worksheetData.push([]);
    worksheetData.push(['Итоговая информация']);
    worksheetData.push(['Общая сумма баллов', totals.totalPoints]);
    worksheetData.push(['Запрос', totals.queryPoints]);
    worksheetData.push(['Отчет', totals.reportPoints]);
    worksheetData.push(['Лимит', totals.limitPoints]);
    worksheetData.push(['Платеж', totals.paymentPoints]);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Данные');
    XLSX.writeFile(workbook, 'client_data.xlsx');
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className='header'>май медиан</div>

      <div className='main'>
        <div className='id-list'>
          <div style={{ marginBottom: '20px' }}>
            <label>Выберите уровень скилла: </label>
            <select value={selectedSkill} onChange={(e) => setSelectedSkill(e.target.value)}>
              <option value="Base">Base</option>
              <option value="PRO">PRO</option>
              <option value="VIP">VIP</option>
            </select>
          </div>
          {entries.map((entry, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <span className='text'>{index + 1}</span>
              <input
                type="text"
                placeholder="Введите ID клиента"
                value={entry.clientId}
                onChange={(e) => handleInputChange(index, 'clientId', e.target.value)}
                className='input-box'
              />
              <select
                value={entry.category}
                onChange={(e) => handleInputChange(index, 'category', e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                {categories.map((cat) => (
                  <option key={cat.name} value={cat.name}>{cat.name}</option>
                ))}
              </select>
            </div>
          ))}
        </div>

        <button className='add-button' onClick={addEntry} style={{ marginTop: '10px' }}>+</button>

        <div className='info'>
          <h3>Итоговая информация</h3>
          <p>Общая сумма баллов: {totals.totalPoints}</p>
          <p>Запрос: {totals.queryPoints}</p>
          <p>Отчет: {totals.reportPoints}</p>
          <p>Лимит: {totals.limitPoints}</p>
          <p>Платеж: {totals.paymentPoints}</p>
        </div>

        <div style={{ width: '400px', margin: '20px auto' }}>
          <h3>Сумма баллов по категориям</h3>
          <Pie
            data={chartData}
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const label = context.label || '';
                      const value = context.raw || 0;
                      return `${label}: ${value.toFixed(1)} баллов`;
                    }
                  }
                }
              }
            }}
          />
        </div>

        <button onClick={exportToExcel} style={{ marginTop: '20px' }}>Скачать Excel</button>

      </div>
    </div>
  );
};

export default App;